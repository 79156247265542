import React, { Component } from 'react';
import {
FacebookShareButton,
LinkedinShareButton,
TwitterShareButton,
WhatsappShareButton,
PinterestShareButton,
RedditShareButton,
TumblrShareButton,
EmailShareButton,
} from 'react-share';
import {FacebookIcon,TwitterIcon,WhatsappIcon,LinkedinIcon,PinterestIcon,RedditIcon,TumblrIcon,EmailIcon,} from 'react-share';

import '../../Content/css/socialShareButtons.css'
export default class SocialShareButtons extends Component {
	render() {
		let {image} = this.props
		let shareUrl = `https://newimageketo.com/${this.props.url}`;
		return (
			<div className="socialShareButtonsWrapper">

				<ul className="flex">
					<li><h5>Share Page: </h5></li>
					<li>
					  <FacebookShareButton url={shareUrl} quote={this.props.description} title={this.props.title} image={this.props.image}>
					      <FacebookIcon size={24} round={true}/>
					  </FacebookShareButton>
					</li>
					<li>
					  <LinkedinShareButton><LinkedinIcon size={24} round={true}/> </LinkedinShareButton>
					</li>
					<li>
					  <TwitterShareButton url={shareUrl} quote={this.props.description} title={this.props.title} image={this.props.image}>
					      <TwitterIcon size={24} round={true}/>
					  </TwitterShareButton>
					</li>
					<li>
					  <WhatsappShareButton url={shareUrl} description={this.props.description} title={this.props.title} image={this.props.image}>
					      <WhatsappIcon size={24} round={true}/>
					  </WhatsappShareButton>
					</li>
					<li>
				      {image&&
					  <PinterestShareButton url={shareUrl} description={this.props.description} title={this.props.title} media={this.props.image}>
					      <PinterestIcon size={24} round={true}/>
					  </PinterestShareButton>
					  }
					</li>
					<li>
					  <RedditShareButton url={shareUrl} description={this.props.description} title={this.props.title} image={this.props.image}>
					      <RedditIcon size={24} round={true}/>
					  </RedditShareButton>
					</li>
					<li>
					  <TumblrShareButton url={shareUrl} description={this.props.description} title={this.props.title} image={this.props.image}>
					      <TumblrIcon size={24} round={true}/>
					  </TumblrShareButton>
					</li>
					<li>
					  <EmailShareButton url={shareUrl} dbody={this.props.description} subject={this.props.title} image={this.props.image}>
					      <EmailIcon size={24} round={true}/>
					  </EmailShareButton>
					</li>
				</ul>
			</div>
		);
	}
}
