import React, { Component } from 'react';
import Helmet from 'react-helmet'
import HtmlParser from 'react-html-parser';
import '../../Content/css/article.css'
import ArticleSidebar from './ArticleSidebar';
import SocialShareButtons from './SocialShareButtons';
import { animateScroll as scroll} from 'react-scroll';
import {Link} from 'gatsby';
import { connect } from "react-redux";
let gravURL = process.env.REACT_APP_GRAV_URL;
const mapStateToProps = (state, props) => {
	return { user: state.user, content: props.content};
};
class Article extends Component {
	constructor(props) {
	  super(props);
	  this._isMounted = false;
	  this.state = {
	  	gravContent: this.props.content.frontmatter,
	  	mediaLink: gravURL + '/blog/' + this.props.url,
	  	pageContent: this.props.content.content,
	  	sidebarContent: this.props.content.sidebar,
	  	height: 0,
	  	width: 0,
	  	loading: false,
	  	media: this.props.content.media,
	  	loadingColor: '#fff',
	  	headContent: this.props.content.head,

	  };
	}
	static getDerivedStateFromProps(props, state) {
		//
		return null
    }
	handleSimPageReload(){
		scroll.scrollTo(0, {
		 	duration: 400,
  			smooth: true,
  		}); 
	}
	handleArticleImages(){
		let imgs = document.getElementById('articleBody').getElementsByTagName("img");
	    for (let i = 0; i < imgs.length; i++) {
	    	let fileName = imgs[i].getAttribute('src');
	    	let replacementSrc = gravURL + fileName;
	    	imgs[i].setAttribute('src', replacementSrc)
	    }
	}
	componentDidMount(){
		this.handleSimPageReload();
		window.addEventListener('resize', this.updateWindowDimensions.bind(this));
		this.updateWindowDimensions();

	}
	handleScroll(){
		scroll.scrollTo(this.state.height - 220, {duration: 500,smooth: true,});
	}

	updateWindowDimensions() {
		this.setState({
			windowWidth: window.innerWidth,
			height: window.innerHeight,
		})
	}
	componentWillUnmount() {
	  window.removeEventListener('resize', this.updateWindowDimensions);
	  this._isMounted = false;
	  
	}
	render() {
		let content = HtmlParser(this.state.pageContent)
		let {title,keywords,facebookTitle,facebookDesc,shareImg,googleDesc,} = this.state.headContent;
		let desktopBanner = this.state.media;
		let useSlider = this.state.windowWidth < 768;
		let {verified} = this.props.user;
		return (
			<div>
				
				<Helmet>
					{title?
						<title>{this.state.headContent.title}</title>
						:
						<title>New Image Keto | Alpha Lipid™ SDII Programme</title>	
					}
					{googleDesc&&
						<meta name="description" content={this.state.headContent.googleDesc} />
					}
					{keywords&&
						<meta name="keywords" content={this.state.headContent.keywords.toString()} />
					}
					<meta property="og:image:width" content="1200" />
					<meta property="og:image:height" content="630" />
					{facebookTitle?
						<meta property="og:title" content={this.state.headContent.facebookTitle}/>
						:
						<meta property="og:title" content={this.state.headContent.title}/>
					}
					{facebookTitle?
						<meta name="twitter:title" content={this.state.headContent.facebookTitle}/>
						:
						<meta property="twitter:title" content={this.state.headContent.title}/>
					}
					{facebookDesc?
						<meta property="og:description" content={this.state.headContent.facebookDesc} />
						:
						<meta property="og:description" content={this.state.headContent.googleDesc} />
					}
					{facebookDesc?
						<meta name="twitter:description" content={this.state.headContent.facebookDesc}/>
						:
						<meta property="twitter:description" content={this.state.headContent.googleDesc} />
					}
					{shareImg&&
						<meta property="og:image" content={gravURL + this.state.headContent.shareImg} />
					}
					{shareImg&&
						<meta property="og:image:url" content={gravURL + this.state.headContent.shareImg}/>
					}
					{shareImg&&
						<meta name="twitter:image" content={gravURL + this.state.headContent.shareImg}/>
					}
					{shareImg&&
						<meta name="twitter:card" content={gravURL + this.state.headContent.shareImg}/>
					}
				</Helmet>
				<div className="headerBannerWrapper clearfix blogPageHeaderBanner z-10 relative bg-gray-200">
					{desktopBanner &&
						<img src={gravURL + this.state.media.desktopBanner} alt={this.state.gravContent.title} height="1000px" width="1920px"/>
					}
					<div className="scrollTriggerWrapper">
						<button className="articleScrollTrigger" onMouseEnter={this.handleScroll.bind(this)}>
							<span className="flaticon-check-2"></span>
						</button>
					</div>
				</div>
				<div className="pageGrid">
					<div className="md:flex md:flex-wrap lg:-mt-20 lg:px-10 relative z-20 bg-white py-8 px-5 w-full">
						<div className="md:w-8/12" id="articleBody">
							{verified? null:
								<div className="mb-5 text-center md:text-left md:hidden">
									<Link to="/free-trial" className="inline-block mx-auto bg-primary text-white font-bold py-2 px-6 rounded-lg">Start FREE 14 Day Trial</Link>
								</div>
							}
							<h1 className="mb-4 text-primary text-3xl font-medium">{this.state.gravContent.title}</h1>
							<div>{content}</div>
							<SocialShareButtons 
								title={this.state.headContent.facebookTitle}
								description={this.state.headContent.facebookDesc}
								image={gravURL + this.state.headContent.shareImg}
								url={this.props.url}
							/>
						</div>
						<div className="md:w-1/12"></div>
						<div className="md:w-3/12">
							{verified? null:
								<div className="mb-5 text-center md:text-left hidden md:block">
									<Link to="/free-trial" className="inline-block mx-auto bg-primary text-white font-bold py-2 px-6 rounded-lg">Start FREE 14 Day Trial</Link>
								</div>
							}
							<ArticleSidebar url={gravURL} sidebarContent={this.state.sidebarContent} useSlider={useSlider} handleLink={this.handleSimPageReload.bind(this)}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const SingleArticle = connect(mapStateToProps,null)(Article);
export default SingleArticle