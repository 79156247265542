import React, {Component} from 'react';
import {Link} from 'gatsby';
import '../../Content/css/Slider.css'
import Slider from "react-slick";
export default class ArticleSidebar extends Component {
  render() {
    let sidebarPosts = this
      .props
      .sidebarContent
      .map((item, key) => <div className="sidebarArticleTile" key={key}>
        <Link to={item.link} onClick={this.props.handleLink}>
          <img src={this.props.url + item.thumbnail} alt={item.header.title}/>
          <h4 className="font-medium">{item.header.title}</h4>
        </Link>
      </div>);
    let slides = this
      .props
      .sidebarContent
      .map((item, key) => <div className="articleFooterSlide" key={key}>
        <Link to={item.link} onClick={this.props.handleLink}>
          <img src={this.props.url + item.thumbnail} alt={item.header.title}/>
          <h4 className="font-medium">{item.header.title}</h4>
        </Link>
      </div>);
    let useSlider = this.props.useSlider === true;
    return (

      <div>
        {useSlider
          ? <div className="mt-3">
              <Slider
                dots={true}
                infinite={true}
                speed={900}
                variableWidth={false}
                useTransform={false}
                arrows={true}
                autoplaySpeed={8000}
                autoplay={true}
                fade={false}
                slidesToShow={2}
                slidesToScroll={2}
                pauseOnHover={true}>
                {slides}
              </Slider>
            </div>
          : <div>{sidebarPosts}</div>
}
      </div>
    );
  }
}
